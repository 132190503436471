var data = new Object();
$(document).ready(function(){
	$("input").placeholder();
	$("input[type='password']").bind("keydown", function(event) {
		// track enter key
		var keycode = (event.keyCode ? event.keyCode : (event.which ? event.which : event.charCode));
		if (keycode == 13) { // keycode for enter key
			$("#postAction").val("email")
			$(".login, .create").submit()
		}
	}); // end of function
	$(".company-name").focus();
	var timer;

	$("#signup").on("keyup", "#id_account_domain", function(){
		//$(this).attr("size", $(this).val().length+2);
		$('<span id="width">').append( $(this).val() ).appendTo('body');
    	$(this).width( $('#width').width() + 10 );
    	$('#width').remove();
	})
	$("#signup").on("click", ".noFacebookLogin", function(){
		$(".emailForm").show()
		$("#id_account_use_facebook").removeAttr("checked");
	})

	$(".createRegularBtn").click(function(){
		$(".emailForm").show();
	})

	/*$("#signup").on("click", ".facebookButton", function(){
		$(".regularLoginButton").addClass("small");
		$(".selected").removeClass("selected");
		$(this).addClass("selected")
		$(this).removeClass("small")
		$(".emailForm").hide();
		login();
	})*/

	/*$(".facebookLoginBtn").click(function(){
		login("user");
	})
	$(".facebookCreateBtn, .facebookMoveOn").click(function(){
		login("connect");
	})
	$("#signup").on("click", ".facebookSignupBtn", function(){
		login("create");
	})*/

	$(".toggleForms").click(function(){
		$("form.connect").toggle();
		$("form.login").toggle();
	})

	$("#login").on("click", ".login_email", function(){
		$("#postAction").val("email")
		$(this).text($(this).data("loading"))
		$(".login").submit();
	})
	$("#login").on("click", ".connect_email", function(){
		$("#postAction").val("email")
		$(this).text($(this).data("loading"))
		$(".connect").submit();
	})
	$("#login").on("click", ".activate_email", function(){
		$("#postAction").val("email")
		$(this).text($(this).data("loading"))
		$(".activate").submit();
	})

	$(".signup-page").on("submit", ".create", function(e){
		$("#postAction").val("email")
		$(".submitForm")[0].disabled = true;
		$(".submitForm").text($(".submitForm").data("loading"))

		return true;
		//e.preventDefault();
		/*var form = $("form").serialize();
		$.ajax({
			url: "/account/signup.json/",
			type: "post",
			data: form,
			success: function(response){
				if (response.status =="success"){
					window.location.href = response.url;
				} else if( response.status =="error"){
					var newHtml = $('<div/>').html(response.html).contents()
					var formHtml = newHtml.find("form").contents()
					$("#signup form").html(formHtml)
				}
			}
		})*/
	})

})